/** @format */

import { navigate } from "gatsby-link";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import Layout from "../../components/Layout";
import { AppContext } from "../../utils/ContextWrapper";
import { formatoNumero } from "../../utils/utils";

function Comprar() {
  // administra el estado de la aplicacion
  const context = useContext(AppContext);
  const totalCarrito = context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento);
  // Mensaje que se muestra para la validación de código
  const [mensajeValidacionCodigo, setMensajeValidacionCodigo] = useState("");

  const [validacionEnvio, setValidacionEnvio] = useState("");
  // permite validr si muestra el boton de validar codigo
  const [validarCodigo, setValidarCodigo] = useState(false);
  // permite indicar si se encuentra procesando una peticion.
  const [loading, setLoading] = useState(false);
  const [cupon, setCupon] = useState("");

  const hadleChange = (event) => {
    setValidacionEnvio("");
    context.actions.asignarEnvio(event.target.id);
  };

  const hadleChangeButton = (event) => {
    // SI EL VALOR DEL CUPON ESTA VACIO OCULTA EL BOTON DE VALIDAR CUPON
    if (event.target.value.length > 0) {
      setValidarCodigo(true);
    } // DE LO CONTRARIO, MUESTRA EL BOTO
    else setValidarCodigo(false);

    // SIEMPRE ASIGNA EL VALOR DEL CUPON
    setCupon(event.target.value.toUpperCase());
  };

  useEffect(() => {
    // se inicia el envio al realizar la carga de la pantalla.
    context.actions.asignarEnvio(0);
    setCupon(context.store.codigoDescuento);
  }, [context.store.primeraVisita, context.actions]);

  return (
    <Layout>
      <div className="comprar-contenedor">
        {totalCarrito > 0 && <h2>El detalle de tu compra: </h2>}

        <br />
        {Object.keys(context.store.carrito).map((propiedad) => (
          <div key={propiedad} className="comprar-contenedor-item">
            {context.store.carrito[propiedad].enOferta === 1 && <span className="oferta-compra">EN OFERTA</span>}
            <div>
              <img
                height={100}
                src={`${process.env.API_URL_CHAPIN}` + context.store.carrito[propiedad].imagen}
                alt={context.store.carrito[propiedad].nombreProducto}
              />
            </div>
            <div className="comprar-contenedor-item-descripcion">
              <h5> {context.store.carrito[propiedad].nombreProducto}</h5>
              <div className="comprar-contenedor-cantidad ">
                <div className="comprar-contenedor-cantidad-boton">
                  <Button
                    variant="light"
                    style={{ backgroundColor: "white ", paddingTop: 3, paddingBottom: 3 }}
                    className="remove-btn-focus"
                    onClick={() => context.actions.decrementarProducto(context.store.carrito[propiedad])}
                  >
                    -
                  </Button>
                  {context.store.carrito[propiedad].cantidad}
                  <Button
                    variant="light"
                    style={{ backgroundColor: "white ", paddingTop: 3, paddingBottom: 3 }}
                    className="remove-btn-focus"
                    onClick={() => context.actions.incrementarProducto(context.store.carrito[propiedad])}
                  >
                    +
                  </Button>
                </div>
                X Q. {formatoNumero(context.store.carrito[propiedad].precioQuetzales)}
              </div>
              {context.store.codigoDescuento?.length > 0 && (
                <>
                  <span>
                    {context.store.carrito[propiedad].usa_cupon == 1 ? "(-) descuento de cupón" : "No aplica cupón"}
                  </span>
                </>
              )}
            </div>
            <div className="comprar-eliminar comprar-contenedor-item-boton">
              <button
                onClick={() =>
                  context.actions.eliminarProducto(context.store.carrito, context.store.carrito[propiedad])
                }
              >
                X
              </button>
            </div>
          </div>
        ))}

        <div className="comprar-total">
          {totalCarrito === 0 && <h1>No cuentas con ningún producto en el carrito de compras.</h1>}
          {totalCarrito > 0 && (
            <>
              <Form>
                <Form.Label>
                  En la compra mínima de Q 300.00, envío gratis dentro del perímetro de la Capital y Carretera a el
                  Salvador.
                  <br />
                  <br /> Las tarifas de envío para tu compra, son las siguientes:
                </Form.Label>
                <Form.Check
                  value={context.store.envio}
                  onChange={hadleChange}
                  inline
                  label={`Ciudad capital Q ${formatoNumero(
                    context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento) < 300
                      ? 25.0
                      : 0.0
                  )}`}
                  name="group1"
                  type="radio"
                  id="1"
                />
                <Form.Check
                  value={context.store.envio}
                  onChange={hadleChange}
                  inline
                  label={`Carretera a El Salvador Q ${formatoNumero(
                    context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento) < 300
                      ? 30.0
                      : 0.0
                  )}`}
                  name="group1"
                  type="radio"
                  id="2"
                />
                <Form.Check
                  value={context.store.envio}
                  onChange={hadleChange}
                  inline
                  label="Municipios de Guatemala Q 30.00"
                  name="group1"
                  type="radio"
                  id="3"
                />
                <Form.Check
                  value={context.store.envio}
                  onChange={hadleChange}
                  inline
                  label="Departamentos Q 40.00"
                  name="group1"
                  type="radio"
                  id="4"
                />
                <br />
                <span style={{ color: "red" }}> {validacionEnvio}</span>
              </Form>
              <br />
              <Form.Group as={Col} md="12" controlId="validaNombre">
                <Form.Label>
                  {context.store.codigoDescuento?.length > 0
                    ? `Se aplicará a tu compra un ${context.store.porcentajeDescuento}% de descuento a productos seleccionados con tu cupón:`
                    : "¿TIENES UN CUPÓN DE DESCUENTO?  INGRESALO AQUÍ."}
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Aquí puedes ingresar tu cupón"
                    aria-describedby="inputGroupPrepend"
                    value={cupon}
                    required
                    name="cupon"
                    onChange={hadleChangeButton}
                    disabled={context.store.codigoDescuento?.length > 0}
                  />
                  {validarCodigo && (
                    <Button
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "orange",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        if (loading) return;

                        setLoading(true);

                        setValidacionEnvio("");
                        fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/descuento/${cupon}`, {
                          method: "GET",
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                          },
                        })
                          .then(async (response) => {
                            setLoading(false);
                            if (response.ok) {
                              var res = await response.json();
                              context.actions.asignarCodigo(cupon, res.codigo);
                              setValidarCodigo(false);
                              setMensajeValidacionCodigo("");
                            } else {
                              setMensajeValidacionCodigo(
                                "El código es invalido o no existe, intenta con un nuevo código"
                              );
                            }
                          })
                          .catch((err) => {
                            setMensajeValidacionCodigo(
                              "El código es invalido o no existe, intenta con un nuevo código"
                            );
                            setLoading(false);
                          });
                      }}
                    >
                      {loading ? "Procesando.." : "Validar cupón"}
                    </Button>
                  )}

                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <br />
              <span style={{ color: "red" }}>{mensajeValidacionCodigo}</span>
              <br />
              <b>TOTAL: </b>
              Q. {formatoNumero(
                context.actions.obtenerTotal(context.store.carrito, context.store.porcentajeDescuento)
              )}{" "}
              + ENVIO
            </>
          )}
        </div>
        {totalCarrito > 0 && (
          <>
            <Button
              variant="light"
              style={{
                backgroundColor: "orange",
                color: "white",
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 10,
                borderWidth: 1,
                borderStyle: "solid",
                marginTop: 20,
                fontWeight: "bold",
              }}
              className="remove-btn-focus"
              onClick={() => {
                if (context.store.envio === 0) {
                  setValidacionEnvio("Debes seleccionar una ubicación de envío");
                } else {
                  setValidacionEnvio("");
                  navigate("/comprar/formulario/");
                }
                return;
              }}
            >
              Continuar con la compra
            </Button>

            <Button
              variant="light"
              style={{
                backgroundColor: "white",
                color: "black",
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 10,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "black",
                marginTop: 20,
                fontWeight: "bold",
              }}
              className="remove-btn-focus"
              onClick={() => navigate("/productos/")}
            >
              Agregar más productos
            </Button>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Comprar;
